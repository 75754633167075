import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService, ConfigService } from '@assist/shared/data';
import { ApiService, qrCodeInfo } from '@cue/api';

@Injectable({
  providedIn: 'root',
})
export class QrCodeGuard implements CanActivate {
  constructor(
    public router: Router,
    public apiService: ApiService,
    public authService: AuthService,
    public configService: ConfigService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> | UrlTree | Observable<UrlTree> {
    const params = route.queryParams;
    const resourceId = params['resourceId'];
    const newsId = params['newsId'];
    const id = route.params['id'];

    if (newsId !== null && newsId !== undefined && newsId !== 'null') {
      return this.router.parseUrl('/news/' + newsId);
    }
    if (resourceId !== null && resourceId !== undefined && resourceId !== 'null' && !this.configService.value.forceResourceToNavigation) {
      return this.router.parseUrl('/reservations/reserve/' + resourceId + '?split=');
    }

    return this.apiService
      .call(
        qrCodeInfo(id, (x) => {
          //TOUCH-2290 QR code not found
          if (x.status === 404) {
            return this.router.parseUrl('/dashboard');
          }
          return null;
        }),
      )
      .pipe(
        map((response) => {
          if (response.success) {
            // je asist uzivatel
            const isAssistUser = this.authService.getApiRefreshToken() != null;
            const resourceId = response.data.resourceId;
            const resourceGuid = response.data?.resourceGuid;
            const newsId = response.data.newsId;
            const hasLocation = response.data?.hasLocation;

            const navigationEnabled = response.data?.navigationEnabled;
            const navigationUrl = response.data?.navigationUrl;
            const qrCodeId = response.data?.qrId;

            const startAreaId = response.data?.startAreaId;

            if (!isAssistUser) {
              if (response.data?.qrCodeInNonNavigableArea) {
                // this.configService.value.forceResourceToNavigation
                if (hasLocation && true) {
                  return this.router.parseUrl('/navigation?qrCodeInNonNavigableArea=true');
                } else {
                  return this.router.parseUrl('/dashboard');
                }
              }
              // pokud je zapnuta navigace, uvazuje se jeste jine flow
              if (navigationEnabled && navigationUrl) {
                // Pokud ma lokaci, pouzije se jako start
                if (hasLocation) {
                  window.location.href =
                    navigationUrl + '?startAreaId=' + startAreaId + '&locationId=' + qrCodeId + '&areaId=' + startAreaId;
                }
              }
            }

            if (newsId != null && newsId !== undefined) {
              return this.router.parseUrl('/news/' + newsId);
            }
            if (resourceGuid != null && resourceGuid !== undefined) {
              let baseUrl = '/dashboard/resource-info?id=' + resourceGuid;
              if (qrCodeId && hasLocation) {
                baseUrl += '&qrCodeId=' + qrCodeId + '&startAreaId=' + startAreaId;
              }
              return this.router.parseUrl(baseUrl);
            }

            if (hasLocation) {
              if (this.configService.value.forceResourceToNavigation) {
                window.location.href = navigationUrl + '?startAreaId=' + startAreaId + '&locationId=' + qrCodeId + '&areaId=' + startAreaId;
              } else if (!response.data?.qrCodeInNonNavigableArea) {
                return this.router.parseUrl('/navigation?startAreaId=' + startAreaId + '&locationId=' + qrCodeId);
              }
            }
            return this.router.parseUrl('/dashboard');
          } else {
            return this.router.parseUrl('/dashboard');
          }
        }),
      );
  }
}
